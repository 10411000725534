import React, { Component } from 'react';
import queryString from 'query-string';
import { css } from "@emotion/core";
import ScaleLoader from 'react-spinners/ScaleLoader';

import { Header } from '../Components/Header';
import { MainRouter } from '../Components/MainRouter';
import { OrderController } from '../Controllers/OrderController';

import { ReactComponent as InfoError } from '../Images/InfoError.svg';

export class SearchPage extends Component {
    static displayName = SearchPage.name;

    api;

    constructor(props) {
        super(props);

        window.ga('set', 'page', window.location.pathname + window.location.search);
        window.ga('send', 'pageview');
        
        Header.setLoggedIn(false);

        var query = queryString.parse(props.location.search.toLowerCase());

        this.state = {
            btnDisabled: true,
            showValidation: false,
            orderNumber: query.ordernumber ? query.ordernumber : '',
            postalCode: query.postalcode ? query.postalcode : '',
            loading: false
        };

        this.validate = this.validate.bind(this);
        this.click = this.click.bind(this);
        this.keyDown = this.keyDown.bind(this)
        this.isValid = this.isValid.bind(this);

        if (this.state.orderNumber && this.state.postalCode) {
            this.click();

            //We need to load the app settings for the order controller here if we're going to retrieve the data from the backend now
            fetch(`${process.env.PUBLIC_URL}/appSettings.json`)
                .then((res) => res.json())
                .then((data) => {
                    this.api = new OrderController({
                        settings: data 
                    });

                    this.doApiCall();
                });
        }
        else {
            this.api = new OrderController();
        }
    }

    render() {
        return (
            <MainRouter>
                <div className='searchBody'>
                    <div className='instructions'>
                        To view the status of your order, please <br /> enter the information below.
                    </div> 
                    <div className={this.state.showValidation === false || this.state.orderNumber !== '' ? 'hide' : 'show-validation-text'}>
                        <InfoError /> Order number is required.
                    </div>
                    <div className={this.state.showValidation === false || this.state.orderNumber !== '' ? 'form-group' : 'form-group show-validation'}>
                        <input name='orderNumber' className='form-control' placeholder='Enter order number...' value={this.state.orderNumber} onKeyDown={this.keyDown} onChange={this.validate} />
                    </div>
                    <div className={this.state.showValidation === false || this.state.postalCode !== '' ? 'hide' : 'show-validation-text'}>
                        <InfoError /> Zip or postal code is required.
                    </div>
                    <div className={this.state.showValidation === false || this.state.postalCode !== '' ? 'form-group' : 'form-group show-validation'}>
                        <input name='postalCode' className='form-control' placeholder='Enter zip or postal code on order...' value={this.state.postalCode} onKeyDown={this.keyDown} onChange={this.validate} />
                    </div>
                    <button type='button' className={!this.state.btnDisabled ? 'btn btn-primary' : 'btn btn-secondary'} onClick={this.click} >
                        SEARCH
                    </button>
                    <ScaleLoader
                        css={css`text-align:center; padding-top:10px;`}
                        color='#fe6848'
                        loading={this.state.loading} />
                </div>
            </MainRouter>
        );
    }

    keyDown(e) {
        if (e.key === 'Enter' && this.isValid()) {
            this.click();
        }
    }

    validate(e) {
        var state = this.state;

        if (e.target.name === 'orderNumber') {
            state.orderNumber = e.target.value;
        } else {
            state.postalCode = e.target.value;
        }

        if (this.isValid()) {
            state.btnDisabled = false;
        } else {
            state.btnDisabled = true;
        }

        this.setState(state);
    }

    isValid() {
        return this.state.orderNumber !== '' && this.state.postalCode !== '';
    }

    click() {
        var state = this.state;
        if (!state.btnDisabled) {
            state.btnDisabled = true;
            state.loading = true;
            this.setState(state);

            if (this.api) {
                this.doApiCall();
            }
        }
        else {
            state.showValidation = true;
            this.setState(state);
        }
    }

    doApiCall() {
        this.api.getOrderByOrderNumberAndPostalCode(this.state.orderNumber, this.state.postalCode)
            .then((data) => {
                Header.setLoggedIn(true);
                sessionStorage.setItem('data', JSON.stringify(data));

                if (data.shipmentType) {
                    MainRouter.routeTo('/' + data.shipmentType.toLowerCase());
                }
                else {
                    MainRouter.routeTo('/error')
                }
            })
            .catch(() => MainRouter.routeTo('/error'));
    }
}
