
export class OrderController {

    settings = null;

    constructor(props) {

        if (!props || props.settings === null) {
            fetch(`${process.env.PUBLIC_URL}/appSettings.json`)
                .then((res) => res.json())
                .then((data) => {
                    this.settings = data;
                });
        }
        else {
            this.settings = props.settings;
        }

        this.getOrderByOrderNumberAndPostalCode = this.getOrderByOrderNumberAndPostalCode.bind(this);
    }

    getOrderByOrderNumberAndPostalCode(orderNumber, postalCode) {
        if (this.settings.mode === "dev") {
            var fakedOrder = this.getFakedOrder(orderNumber, postalCode);

            if (fakedOrder) {
                return fakedOrder;
            }
        }

        return fetch(this.settings.baseOrderApiUrl + '/' + orderNumber + '/' + postalCode, { method: 'get' }).then((res) => res.json());
    }

    getFakedOrder(orderNumber, postalCode) {
        if (orderNumber === 'large-carrier') {
            return new Promise((resolve) =>
                resolve({
                    orderNumber: '0293829019820192VBN',
                    postalCode: postalCode,
                    orderCreateDate: '2020-09-01 09:13:00.0000000+00:00',
                    poNumber: null,
                    loadId: '12432434',
                    shipmentStatus: null,
                    shipmentType: 'LargeCarrier',
                    estimatedShipDate: null,
                    estimatedDeliveryDate: null,
                    actualShipDate: null,
                    actualCompletionDate: null,
                    requestedDate: null,
                    orderLastUpdated: '2020-09-03 10:15:00.0000000+00:00',
                    carrierName: 'DHL',
                    carrierTrackingNumber: '325422552231423',
                    carrierTrackingUrl: 'http://www.dhl-usa.com/content/us/en/express/tracking.shtml?brand=DHL&AWB=325422552231423',
                    originWarehouse: null,
                    destinationStreet: null,
                    destinationCity: null,
                    destinationZip: null,
                    destinationCountry: null,
                    palletCount: null,
                    notes: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum orci neque, dapibus nec pretium a, fermentum id elit. Etiam lobortis lacinia commodo. Donec et dolor maximus, volutpat libero ac, laoreet leo. Donec feugiat, purus id tincidunt vestibulum, turpis elit pretium nisi, in convallis mi velit vel felis. In hac habitasse platea dictumst.'
                }));
        }
        else if (orderNumber === 'small-carrier') {
            return new Promise((resolve) =>
                resolve({
                    orderNumber: '0293829019820192VBN',
                    postalCode: postalCode,
                    orderCreateDate: '2020-09-01 09:13:00.0000000+00:00',
                    poNumber: '35645463',
                    loadId: '12432434',
                    shipmentStatus: 'Delivered',
                    shipmentType: 'SmallCarrier',
                    estimatedShipDate: '2020-09-01 09:13:00.0000000+00:00',
                    estimatedDeliveryDate: '2020-09-03 10:15:00.0000000+00:00',
                    actualShipDate: '2020-09-01 09:13:00.0000000-07:00',
                    actualCompletionDate: '2020-09-03 10:15:00.0000000+00:00',
                    requestedDate: null,
                    orderLastUpdated: '2020-09-03 10:15:00.0000000+00:00',
                    carrierName: 'ABF Freight System Inc',
                    carrierTrackingNumber: '325422552231423',
                    carrierTrackingUrl: 'https://um.com',
                    originWarehouse: 'SEATTLE',
                    destinationStreet: '1234 Street Name',
                    destinationCity: 'City',
                    destinationState: 'ST',
                    destinationZip: '12345',
                    destinationCountry: 'Country',
                    palletCount: '42',
                    notes: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum orci neque, dapibus nec pretium a, fermentum id elit. Etiam lobortis lacinia commodo. Donec et dolor maximus, volutpat libero ac, laoreet leo. Donec feugiat, purus id tincidunt vestibulum, turpis elit pretium nisi, in convallis mi velit vel felis. In hac habitasse platea dictumst.',
                }));
        }
        else if (orderNumber === 'will-call') {
            return new Promise((resolve) =>
                resolve({
                    orderNumber: '0293829019820192VBN',
                    postalCode: postalCode,
                    orderCreateDate: '2020-09-01 09:13:00.0000000+00:00',
                    poNumber: null,
                    loadId: '12432434',
                    shipmentStatus: 'PickedUp',
                    shipmentType: 'WillCall',
                    estimatedShipDate: null,
                    estimatedDeliveryDate: null,
                    actualShipDate: null,
                    actualCompletionDate: '2020-09-03 10:15:00.0000000+00:00',
                    requestedDate: '2020-09-03 10:15:00.0000000+00:00',
                    orderLastUpdated: '2020-09-03 10:15:00.0000000+00:00',
                    carrierName: 'WILL CALL',
                    carrierTrackingNumber: null,
                    carrierTrackingUrl: null,
                    originWarehouse: null,
                    destinationStreet: '1234 Street Name',
                    destinationCity: 'City',
                    destinationState: 'ST',
                    destinationZip: '12345',
                    destinationCountry: 'Country',
                    palletCount: '42',
                    notes: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum orci neque, dapibus nec pretium a, fermentum id elit. Etiam lobortis lacinia commodo. Donec et dolor maximus, volutpat libero ac, laoreet leo. Donec feugiat, purus id tincidunt vestibulum, turpis elit pretium nisi, in convallis mi velit vel felis. In hac habitasse platea dictumst.'
                }));
        }

        return null;
    }
}