import React, { Component } from 'react';
import { MainRouter } from '../Components/MainRouter';
import { TopSection } from '../Components/TopSection';
import Parser from 'html-react-parser';

export class LargeCarrierPage extends Component {
    static displayName = LargeCarrierPage.name;
    
    constructor(props) {
        super(props);

        window.ga('set', 'page', window.location.pathname + window.location.search);
        window.ga('send', 'pageview');

        this.state = {
            data: JSON.parse(sessionStorage.getItem('data'))
        };
    }
    
    render() {
        
        const getComposedLinks = () => {
            if (this.state.data.carrierTrackingNumber.split(',').length > 1) {
                return this.state.data.carrierTrackingNumber.split(',').map(obj => 
                    <div>
                        <a className='center tracking-link' href={this.state.data.carrierTrackingUrl + obj} target="_blank" rel="noopener noreferrer">
                            {obj}
                        </a>
                        <br />
                    </div>
                );
            } else {
                return <a className='center tracking-link' href={this.state.data.carrierTrackingUrl } target="_blank" rel="noopener noreferrer">
                    {this.state.data.carrierTrackingNumber}
                </a>
            };          
        };

        return (
            <MainRouter>
                <div>
                    <TopSection data={this.state.data} />
                    <div className='line' />
                    <div className='center bottom-20'>
                        <h4> SHIPMENT SUMMARY </h4>
                    </div>
                    <div className='center shipment-summary-section pull-left-mobile'>
                        <div className='pull-left-mobile'>
                            {!this.state.data.carrierTrackingNumber ? 
                                'Your order is processing, it may take up to 24 hours to generate a tracking number. Once a tracking number is created we will display it below.' :
                                Parser('Use the Tracking Number below to check the carrier\'s tracking site <br class=\'hide-mobile\' />for a detailed shipment summary.')
                            }
                        </div>
                        <div className='center top-30 pull-left-mobile'>
                            Carrier Tracking Number(s):
                        </div>
                        <div className='pull-left-mobile'>
                            {!this.state.data.carrierTrackingNumber &&
                                <div className='tracking-link'>- -</div>
                            }
                            {this.state.data.carrierTrackingNumber && this.state.data.carrierTrackingUrl ? 
                                 getComposedLinks()
                                :
                                <div className='center tracking-link'>
                                    {this.state.data.carrierTrackingNumber}
                                </div>
                            }
                        </div>
                    </div>
                    <div className='line' />
                    <div className={this.state.data.notes ? 'notes-section' : 'hide'}>
                        <div className='bottom-20 desktop-view'>
                            <h4>NOTES</h4>
                        </div>
                        <div className='mobile-view'>Notes:</div>
                        {this.state.data.notes}
                    </div>
                </div>
            </MainRouter>
        );
    }
}
