import React, { Component } from 'react';
import { DateTimeHelper } from '../Helpers/DateTimeHelper';

export class TopSection extends Component {
    static displayName = TopSection.name;

    constructor(props) {
        super(props);
        this.state = props.data;
    }

    render() {
        return (
            <div className='row'>
                <div className='col-xl-4 bottom-20'>
                    Order Number: <br />
                    <div className='top-details'>{this.state.orderNumber}</div>
                </div>
                <div className='col-xl-4 bottom-20'>
                    Carrier Name: <br />
                    <div className='top-details'>{this.state.carrierName ? this.state.carrierName : '--'}</div>
                </div>
                <div className='col-xl-4'>
                    Last Updated: <br />
                    <div className='top-details'>{DateTimeHelper.formatDateTime(this.state.orderLastUpdated, 'll')}</div>
                </div>
            </div>
        );
    }
}
