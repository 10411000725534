import React, { Component } from 'react';
import Parser from 'html-react-parser';

export class Modal extends Component {
    static displayName = Modal.name;

    constructor(props) {
        super(props);
        this.state = {
            close: props.onClose,
            title: props.title,
            body: props.body,
            centerBodyText: props.centerBodyText
        };
    }

    render() {
        return (
            <div>
                <div className='modal-background' onClick={this.state.close} />
                <div className='modal-frame'>
                    <h3>
                        {this.state.title}
                    </h3>
                    <div className={this.state.centerBodyText === 'true' ? 'modal-text center' : 'modal-text'}>
                        {Parser(this.state.body)}
                    </div>
                    <button type='button' className='btn btn-primary' onClick={this.state.close}>
                        CLOSE
                    </button>
                </div>
            </div>
        );
    }
}
