import React, { Component } from 'react';
import { MainRouter } from './MainRouter';

import { ReactComponent as Logo } from '../Images/CountryMaltLogo.svg';
import { ReactComponent as MagnifyingGlass } from '../Images/MagnifyingGlass.svg';

export class Header extends Component {
    static displayName = Header.name;

    static setLoggedIn;

    constructor(props) {
        super(props);
        this.state = {
            loggedIn: localStorage.getItem('loggedIn')
        };

        this.click = this.click.bind(this);

        Header.setLoggedIn = (l) => {
            var state = this.state;
            state.loggedIn = l.toString();
            localStorage.setItem('loggedIn', l.toString());
            this.setState(state);
        };
    }

    render() {
        return (
            <div className='header'>
                <div className='header-body'>
                    <a href="https://countrymaltgroup.com/" target="_blank" rel="noopener noreferrer"><Logo className='brand' /></a>
                    <div className='app-name'>
                        Track with Ease
                    </div>
                </div>
                {this.state.loggedIn === 'true' &&
                    <div className='subHeader'>
                        <MagnifyingGlass onClick={this.click} />
                        &nbsp;
                        <a onClick={this.click}>Check another order</a>
                    </div>
                }
            </div>
        );
    }

    click() {
        Header.setLoggedIn(false);
        MainRouter.routeTo('/');
    }
}
