import React, { Component } from 'react';
import { DateTimeHelper } from '../Helpers/DateTimeHelper';

import { MainRouter } from '../Components/MainRouter';
import { Modal } from '../Components/Modal';
import { OrderDetails } from '../Components/OrderDetails';
import { TopSection } from '../Components/TopSection';

import { ReactComponent as Processing } from '../Images/LoadingShipment.svg';
import { ReactComponent as Ready } from '../Images/WillCallReady.svg';
import { ReactComponent as PickedUp } from '../Images/CheersMakingBeer.svg';
import { ReactComponent as Info } from '../Images/Info.svg';
import { ReactComponent as OrderDetailsIcon } from '../Images/OrderDetails.svg';
import { ReactComponent as Chevron } from '../Images/Chevron.svg';

export class WillCallPage extends Component {
    static displayName = WillCallPage.name;

    constructor(props) {
        super(props);
        
        window.ga('set', 'page', window.location.pathname + window.location.search);
        window.ga('send', 'pageview');

        this.state = {
            data: JSON.parse(sessionStorage.getItem('data')),
            showModal: false,
            modalTitle: '',
            modalBody: ''
        };

        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.detailsClick = this.detailsClick.bind(this);

        let pickupAddressParts = [];

        if (this.state.data.destinationStreet)
            pickupAddressParts.push(this.state.data.destinationStreet);
        
        if (this.state.data.destinationCity)
            pickupAddressParts.push(this.state.data.destinationCity);
        
        if (this.state.data.destinationState || this.state.data.destinationZip)
            pickupAddressParts.push([this.state.data.destinationState, this.state.data.destinationZip].join(' '));
        
        this.pickupAddress = pickupAddressParts.join(', ');

        this.shipmentStatus = this.state.data.shipmentStatus.toUpperCase();
    }

    render() {

        return (
            <MainRouter>
                <div>
                    <TopSection data={this.state.data} />
                    <div className='line' />
                    <div className='center fit-content desktop-view'>
                        <div className='center fit-content bottom-10'>
                            <div className='status-col'>
                                <div className="ellipse-selected">
                                    <Processing className='status-image-1' />
                                </div>
                                <div className={this.isInStatus(['READYFORPICKUP', 'PICKEDUP']) ? 'connector-complete' : 'connector-pending'} />
                            </div>
                            <div className='status-col'>
                                <div className={this.isInStatus(['READYFORPICKUP', 'PICKEDUP']) ? 'ellipse-selected' : 'ellipse'}>
                                    <Ready className='status-image-2' />
                                </div>
                                <div className={this.isInStatus('PICKEDUP') ? 'connector-complete' : 'connector-pending'} />
                            </div>
                            <div className='status-col-last'>
                                <div className={this.isInStatus('PICKEDUP') ? 'ellipse-selected' : 'ellipse'}>
                                    <PickedUp className='status-image-3' />
                                </div>
                            </div>
                        </div>
                        <div className='row status-width'>
                            <div className='col-4'>
                                <div className='center fit-content'>
                                    <h4 className={this.isInStatus('PROCESSING') ? '' : 'status-text-pending'}>PROCESSING</h4>
                                    {this.isInStatus('PROCESSING') &&
                                        <Info className='info-image' onClick={() => this.showModal('PROCESSING')} />
                                    }
                                </div>
                            </div>
                            <div className='col-4'>
                                <div className='center fit-content'>
                                    <h4 className={this.isInStatus('READYFORPICKUP') ? '' : 'status-text-pending'}>READY FOR PICK UP</h4>
                                    {this.isInStatus('READYFORPICKUP') &&
                                        <Info className='info-image' onClick={() => this.showModal('READYFORPICKUP')} />
                                    }
                                    {this.isInStatus('READYFORPICKUP') && this.pickupAddress.length > 0 &&
                                        <div className='center'>
                                            {this.pickupAddress}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='col-4'>
                                <div className='center fit-content'>
                                    <h4 className={this.isInStatus('PICKEDUP') ? '' : 'status-text-pending'}>PICKED UP</h4>
                                    {this.isInStatus('PICKEDUP') &&
                                        <Info className='info-image' onClick={() => this.showModal('PICKEDUP')} />
                                    }
                                    {this.isInStatus('PICKEDUP') &&
                                        <div className='center'>
                                            {DateTimeHelper.formatDateTime(this.state.data.actualCompletionDate, 'lll')}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='center mobile-view'>
                        <div className='center ellipse-mobile bottom-10'>
                            {
                                this.isInStatus('PROCESSING') ? <Processing className='status-image-1' /> :
                                    this.isInStatus('READYFORPICKUP') ? <Ready className='status-image-2' /> :
                                        <PickedUp className='status-image-3' />
                            }
                        </div>
                        <div className='center fit-content'>
                            <h4>
                                {
                                    this.isInStatus('PROCESSING') ? 'PROCESSING' :
                                        this.isInStatus('READYFORPICKUP') ? 'READY FOR PICK UP' : 'PICKED UP'
                                }
                            </h4>
                            <Info className='info-image' onClick={() => this.showModal(this.shipmentStatus)} />
                            {this.isInStatus('READYFORPICKUP') && this.pickupAddress.length > 0 &&
                                <div className='center'>
                                    {this.pickupAddress}
                                </div>
                            }
                            {this.isInStatus('PICKEDUP') &&
                                <div className='center'>
                                    {DateTimeHelper.formatDateTime(this.state.data.actualCompletionDate, 'lll')}
                                </div>
                            }
                        </div>
                    </div>
                    <div className='line' />
                    <div className='row order-details-section'>
                        <div className='col-xl-4 desktop-view'>
                            <h4 className='bottom-20'>ORDER DETAILS</h4>
                            <OrderDetails data={this.state.data} />
                        </div>
                        <div className='center btn-sub-mobile mobile-view bottom-20' onClick={this.detailsClick}>
                            <OrderDetailsIcon className='adjust-order-details-image' />
                            <h4 className='order-details-text-mobile'>ORDER DETAILS</h4>
                            <Chevron className='right down-15' />
                        </div>
                        <div className={this.state.data.notes ? 'col-xl-4' : 'hide'}>
                            <div className='bottom-20 desktop-view'>
                                <h4>NOTES</h4>
                            </div>
                            <div className='mobile-view'>Notes:</div>
                            {this.state.data.notes}
                        </div>
                    </div>
                </div>
                {this.state.showModal &&
                    <Modal onClose={this.closeModal}
                           title={this.state.modalTitle}
                           body={this.state.modalBody}
                           centerBodyText='true'
                    />}
            </MainRouter>
        );
    }

    showModal(target) {
        var state = this.state;
        var msg = '';

        if (target === 'PROCESSING') {
            state.modalTitle = 'PROCESSING';
            msg = 'Order is being picked and prepared for pick up.';
        }
        else if (target === 'READYFORPICKUP') {
            state.modalTitle = 'READY FOR PICK UP';
            msg = 'Order is ready to be picked up by customer at will call.';
        }
        else {
            state.modalTitle = 'PICKED UP';
            msg = 'Order has been picked up by customer.';
        }

        state.modalBody = `<div>${msg}</div><div class="stage-of-process">* Your order is in this stage of the process.</div>`;

        state.showModal = true;
        this.setState(state);
    }

    isInStatus(status) {
        if (status && status.length)
            return status.indexOf(this.shipmentStatus) >= 0;
        
        return this.shipmentStatus === status;
    }

    closeModal() {
        var state = this.state;
        state.showModal = false;
        this.setState(state);
    }

    detailsClick() {
        MainRouter.routeTo('/orderdetails', '/willcall', this.state.data);
    }
}
