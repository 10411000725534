import moment from 'moment';

export class DateTimeHelper {
    static formatDateTime(dateTime, format) {
        // Can't do operations on a null date. Simply return null.
        if (dateTime === null)
            return null;
        
        // Strip the timezone information from the date before parsing into moment. 
        // Dates are in the customer's local time, but stored in the DB as UTC.
        var dt = moment(dateTime.substring(0, dateTime.length - 6));
        
        // If the time part is all zeros, this indicates that no time was set in the database value. 
        // Therefore we want to drop the time display by using the 'll' format instead.
        if (format === 'lll' && (dt.hour() + dt.minute() + dt.seconds() + dt.milliseconds()) === 0) { 
            format = 'll';
        }

        return dt.format(format);
    }
}