import React, { Component } from 'react';
import { DateTimeHelper } from '../Helpers/DateTimeHelper';

export class OrderDetails extends Component {
    static displayName = OrderDetails.name;

    constructor(props) {
        super(props);
        this.state = props.data;

        // Check all parts to ensure we have something to display.
        this.hasAddress = this.state.shippingStreet ||
            this.state.shippingCity ||
            this.state.shippingState ||
            this.state.shippingZip ||
            this.state.shippingCountry;

        this.hasDestinationCityStateZip = this.state.destinationCity || this.state.destinationState || this.state.destinationZip;

        this.hasShippingCityStateZip = this.state.shippingCity || this.state.shippingState || this.state.shippingZip;

        this.destCityStateDelim = this.state.destinationCity && (this.state.destinationState || this.state.destinationZip) ? ',' : '';
    }

    render() {
        return (
            <div>
                Order Number: <br />
                <div className='order-details bottom-20'>{this.state.orderNumber}</div>
                <div className={this.state.poNumber ? '' : 'hide'}>
                    Customer PO #: <br />
                    <div className='order-details bottom-20'>{this.state.poNumber}</div>
                </div>
                <div className={this.state.loadId ? '' : 'hide'}>
                    Load ID: <br />
                    <div className='order-details bottom-20'>{this.state.loadId}</div>
                </div>
                Order Creation Date: <br />
                <div className='order-details bottom-20'>{DateTimeHelper.formatDateTime(this.state.orderCreateDate, 'll')}</div>
                <div className={this.state.shipmentType === 'SmallCarrier' ? 'bottom-20' : 'hide'}>
                    <div className={this.state.carrierTrackingNumber ? '' : 'hide'}>
                        Pro # / Tracking #: <br />
                        <div className='order-details bottom-20'>{this.state.carrierTrackingNumber}</div>
                    </div>
                    Carrier Name: <br />
                    <div className='order-details bottom-20'>{this.state.carrierName ? this.state.carrierName : '--'}</div>
                    <div className={this.state.originWarehouse ? '' : 'hide'}>
                        Shipping Point Origin: <br />
                        <div className='order-details bottom-20'>{`${this.state.shippingCity}, ${this.state.shippingState}`}</div>
                    </div>
                    Delivery Location: <br />
                    <div className={this.state.destinationStreet ? 'order-details' : 'hide'}>{this.state.destinationStreet}</div>
                    <div className={this.hasDestinationCityStateZip ? 'order-details' : 'hide'}>{this.state.destinationCity}{this.destCityStateDelim} {this.state.destinationState} {this.state.destinationZip}</div>
                    <div className={this.state.destinationCountry ? 'order-details' : 'hide'}>{this.state.destinationCountry}</div>
                    {!this.hasAddress &&
                        <div className='order-details'>--</div>
                    }
                </div>
                <div className={this.state.shipmentType === 'WillCall' ? '' : 'hide'}>
                    <div className={this.state.requestedDate ? '' : 'hide'}>
                        Requested Pick Up Date: <br />
                        <div className='order-details bottom-20'>{DateTimeHelper.formatDateTime(this.state.requestedDate, 'lll')}</div>
                    </div>
                    <div className={this.hasAddress ? '' : 'hide'}>
                        Pick Up Location: <br />
                        <div className={this.state.shippingStreet ? 'order-details' : 'hide'}>{this.state.shippingStreet}</div>
                        <div className={this.hasShippingCityStateZip ? 'order-details' : 'hide'}>{this.state.shippingCity} {this.state.shippingState} {this.state.shippingZip}</div>
                        <div className={this.state.shippingCountry ? 'order-details' : 'hide'}>{this.state.shippingCountry}</div>
                    </div>
                </div>
            </div>
        );
    }
}
