import React, { Component } from 'react';
import { MainRouter } from '../Components/MainRouter';

export class ErrorPage extends Component {
    static displayName = ErrorPage.name;

    constructor(props) {
        super(props);
       
        window.ga('set', 'page', window.location.pathname + window.location.search);
        window.ga('send', 'pageview');
    }

    render() {
        return (
            <MainRouter>
                <div className='searchBody'>
                    <h3>
                        Oops! Tracking information not found!
                    </h3>
                    <div className='instructions'>
                        Our logistics team is here to help!<br />
                        With your order number and postal/zip code ready, you can email <a href="mailto:cmglogisticsteam@countrymalt.com">cmglogisticsteam@countrymalt.com</a> or call 844-247-3344
                    </div>
                    <button type='button' className='btn btn-primary' onClick={this.click}>
                            TRY AGAIN
                    </button>
                </div>
            </MainRouter>
        );
    }

    click() {
        MainRouter.routeTo('/');
    }
}
