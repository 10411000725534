import React, { Component } from 'react';
import { Redirect } from "react-router";

export class MainRouter extends Component {
    static displayName = MainRouter.name;

    static routeTo;

    constructor(props) {
        super(props);
        this.state = {
            route: '',
            callback: '',
            fromState: null
        };

        MainRouter.routeTo = (to, from, fromState) => {
            var state = this.state;
            state.route = to;
            state.callback = from;
            state.fromState = fromState;
            this.setState(state);
        };
    }

    render() {
        if (this.state.route !== '') {
            return (<Redirect
                to={{
                    pathname: this.state.route,
                    state: {
                        fromState: this.state.fromState,
                        callback: this.state.callback
                    }
                }} />);
        }

        return (
            <div className='main-router'>
                {this.props.children}
            </div>
        );
    }
}
