import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './Components/Layout';

import { SearchPage } from './Pages/SearchPage';
import { ErrorPage } from './Pages/ErrorPage';
import { WillCallPage } from './Pages/WillCallPage';
import { OrderDetailsPage } from './Pages/OrderDetailsPage';
import { LargeCarrierPage } from './Pages/LargeCarrierPage';
import { SmallCarrierPage } from './Pages/SmallCarrierPage';
import { ShipmentSummaryPage } from './Pages/ShipmentSummaryPage';

import './Styles.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
        <Layout>
            <Route exact path='/' component={SearchPage} />
            <Route exact path='/error' component={ErrorPage} />
            <Route exact path='/willcall' component={WillCallPage} />
            <Route exact path='/orderdetails' component={OrderDetailsPage} />
            <Route exact path='/largecarrier' component={LargeCarrierPage} />
            <Route exact path='/smallcarrier' component={SmallCarrierPage} />
            <Route exact path='/shipmentsummary' component={ShipmentSummaryPage} />
        </Layout>
    );
  }
}
