import React, { Component } from 'react';

export class SmallCarrierDisclaimer extends Component {
    
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="disclaimer">
                <p>NOTE: Actual Delivered time is based on shipping point of origin.</p>
                <p className='bottom-10'>For questions regarding your shipment, please contact our Logistics Team at <a href="mailto: cmglogistics@countrymalt.com">cmglogistics@countrymalt.com</a> or call <span style={{ whiteSpace: 'nowrap' }}><a href="tel:+18442743344">844-274-3344</a></span>.</p>
            </div>
        );
    }
}
