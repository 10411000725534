import React, { Component } from 'react';
import { MainRouter } from '../Components/MainRouter';
import { ShipmentSummary } from '../Components/ShipmentSummary';

import { ReactComponent as Chevron } from '../Images/Chevron.svg';

export class ShipmentSummaryPage extends Component {
    static displayName = ShipmentSummaryPage.name;

    constructor(props) {
        super(props);

        window.ga('set', 'page', window.location.pathname + window.location.search);
        window.ga('send', 'pageview');
        
        this.state = props.location.state;

        this.back = this.back.bind(this);
    }

    render() {
        
        return (
            <MainRouter>
                <div className='top-10 back' onClick={this.back}>
                    <Chevron className='flip up-5' />
                    <h4 className='pad-5'>BACK</h4>
                </div>
                <div className='line top-10' />
                <ShipmentSummary data={this.state.fromState} />
            </MainRouter>
        );
    }

    back() {
        MainRouter.routeTo(this.state.callback);
    }
}
