import React, { Component } from 'react';
import moment from 'moment';
import { Modal } from './Modal'

import AltLogo from '../Images/CmgAltLogo.png';
import { ReactComponent as Facebook } from '../Images/Facebook.svg';
import { ReactComponent as Twitter } from '../Images/Twitter.svg';
import { ReactComponent as Instagram } from '../Images/Instagram.svg';
import { ReactComponent as LinkedIn } from '../Images/LinkedIn.svg';


export class Footer extends Component {
    static displayName = Footer.name;

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            modalTitle: 'Need Help?',
            modalBody: 'If you have questions, please contact our Logistics Team at <a href="mailto:cmglogisticsteam@countrymalt.com">cmglogisticsteam@countrymalt.com</a> or call <a href="tel:+18442743344">844-274-3344</a>.'
        };

        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    render() {
        return (
            <div className='footer'>
                <div className='footer-body'>
                    <div className='row'>
                        <div className='col-xl-4'>
                            <div><a href='https://countrymaltgroup.com/careers/' className='footer-link'>CAREERS</a></div>
                            <div><a href='https://countrymaltgroup.com/account-request/' className='footer-link'>REQUEST AN ACCOUNT</a></div>
                            <div>
                                <a href='https://www.unitedmalt.com/wp-content/uploads/2020/03/United-Malt-Privacy-Policy.pdf' className='footer-link'>PRIVACY POLICY</a>
                                &emsp;|&emsp;
                                <a href='https://countrymaltgroup.com/cookie-policy/' className='footer-link'>COOKIE POLICY</a>
                            </div>
                            <div><a href='http://analytics-au.clickdimensions.com/cn/avtta/CCPA' className='footer-link'>DO NOT SELL MY PERSONAL INFORMATION</a></div>
                        </div>
                        <div className='col-xl-4 footer-center'>
                            <img src={AltLogo} alt="Country Malt Group" />
                        </div>
                        <div className='col-xl-4'>
                            <div className='footer-right'>
                                <div className='social-section'>
                                    <a href="http://facebook.com/countrymalt/" title="Follow on Facebook" target="_blank" rel="noopener noreferrer">
                                        <Facebook className='social-icons' />
                                    </a>
                                    &emsp;
                                    <a href="http://twitter.com/CountryMalt" title="Follow on Twitter" target="_blank" rel="noopener noreferrer">
                                        <Twitter className='social-icons' />
                                    </a>
                                    &emsp;
                                    <a href="http://instagram.com/countrymalt/" title="Follow on Instagram" target="_blank" rel="noopener noreferrer">
                                        <Instagram className='social-icons' />
                                    </a>
                                    &emsp;
                                    <a href="https://www.linkedin.com/company/the-country-malt-group/" title="Follow on LinkedIn" target="_blank" rel="noopener noreferrer">
                                        <LinkedIn className='social-icons' />
                                    </a>
                                </div>
                                <a className='footer-link' onClick={this.showModal}>Need Help?</a>
                            </div>
                        </div>
                    </div>
                    <div className='white-line' />
                    <div className='center copyright'>
                        &copy; {moment().year()} Country Malt Group. All rights reserved.
                    </div>
                </div>
                {this.state.showModal &&
                    <Modal onClose={this.closeModal}
                        title={this.state.modalTitle}
                        body={this.state.modalBody}
                        centerBodyText='true'
                    />}
            </div>
        );
    }

    showModal() {
        var state = this.state;
        state.showModal = true;
        this.setState(state);
    }

    closeModal() {
        var state = this.state;
        state.showModal = false;
        this.setState(state);
    }
}
