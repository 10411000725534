import 'react-app-polyfill/ie11';
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const tagManagerArgs = {
  gtmId: 'GTM-PZNZCJ4'
};

TagManager.initialize(tagManagerArgs);
ReactGA.initialize('UA-160757440-4');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  rootElement);

registerServiceWorker();

