import React, { Component } from 'react';
import { MainRouter } from '../Components/MainRouter';
import { OrderDetails } from '../Components/OrderDetails';

import { ReactComponent as Chevron } from '../Images/Chevron.svg';

export class OrderDetailsPage extends Component {
    static displayName = OrderDetailsPage.name;

    constructor(props) {
        super(props);
        this.state = props.location.state;

        window.ga('set', 'page', window.location.pathname + window.location.search);
        window.ga('send', 'pageview');

        this.back = this.back.bind(this);
    }

    render() {
        return (
            <MainRouter>
                <div className='top-10 back' onClick={this.back}>
                    <Chevron className='flip up-5' />
                    <h4 className='pad-5'>BACK</h4>
                </div>
                <div className='line top-10' />
                <div className='center bottom-10'>
                    <h4> ORDER DETAILS </h4>
                </div>
                <OrderDetails data={this.state.fromState} />
            </MainRouter>
        );
    }

    back() {
        MainRouter.routeTo(this.state.callback);
    }
}
