import React, { Component } from 'react';
import { DateTimeHelper } from '../Helpers/DateTimeHelper';
import { SmallCarrierDisclaimer } from '../Components/SmallCarrierDisclaimer';

import { ReactComponent as Checkmark } from '../Images/Checkmark.svg'

export class ShipmentSummary extends Component {
    static displayName = ShipmentSummary.name;

    constructor(props) {
        super(props);
        this.state = props.data;

        // Coalesce dates into a single value.
        this.shipDate = this.state.actualShipDate ? this.state.actualShipDate : this.state.estimatedShipDate;
        this.deliveryDate = this.state.actualCompletionDate ? this.state.actualCompletionDate : this.state.estimatedDeliveryDate;
        this.format = 'll';
        this.carrierTrackingUrl = this.state.carrierTrackingUrl === '' ? null : this.state.carrierTrackingUrl;
        this.carrierTrackingNumber = this.state.carrierTrackingNumber;

        // NOTE: ShipmentSummary is only for Small Carrier. WillCall is picked up by the customer, and LargeCarrier shipment status is handled externally.
        if (this.state.shipmentStatus.toUpperCase() === 'DELIVERED') {
            this.shipDateLabel = 'Actual';
            this.deliveryDateLabel = 'Actual';
            this.isDelivered = true;
        }
        else if (this.state.shipmentStatus.toUpperCase() === 'INTRANSIT') {
            this.shipDateLabel = 'Actual';
            this.deliveryDateLabel = 'Estimated';
        }
        else if (this.state.shipmentStatus.toUpperCase() === 'PROCESSING') {
            this.shipDateLabel = 'Estimated';
            this.deliveryDateLabel = 'Estimated';
            this.format = 'll';
            this.isProcessing = true;
        }
    }

    render() {
        return (
            <div className="ship-summary-content">
                <h4 className='center bottom-10'>SHIPMENT SUMMARY</h4>
                <div className='row shipment-status-section'>
                    {this.isProcessing &&
                        <div className='row shipment-status-section'>
                        <div className='col-2 pad-top-15'>
                            <div className='shipment-ellipse'></div>
                            <div className='shipment-line'></div>
                            <div className='shipment-ellipse'></div>
                        </div>
                        <div className='col-10'>
                            <div className='text-left'>
                                {this.shipDateLabel} Ship Date:
                            </div>
                            <div className='shipment-details bottom-45'>
                                {!this.shipDate ? '- -' : DateTimeHelper.formatDateTime(this.shipDate, this.format)}
                            </div>
                            <div className='text-left'>
                                {this.deliveryDateLabel} Delivery Date:
                            </div>
                            <div className='shipment-details'>- -</div>
                        </div>                                  
                        </div>
                    }
                    {!this.isProcessing &&                    
                        <div className='col-2 pad-top-15'>
                            {this.shipDate &&
                                <div className='shipment-ellipse'>
                                    {this.shipDate &&
                                        <Checkmark className='align-checkmark' />
                                    }
                                </div>
                            }
                            {this.shipDate &&
                                <div className='shipment-line' />
                            }
                            <div className='shipment-ellipse'>
                                {this.isDelivered &&
                                    <Checkmark className='align-checkmark' />
                                }
                        </div>                       
                        </div>
                    }                    
                    {!this.isProcessing &&
                        <div className='col-10 '>
                            {this.shipDate &&
                                <div className='text-left'>
                                    {this.shipDateLabel} Ship Date:
                                </div>
                            }
                            {this.shipDate &&
                                <div className='shipment-details bottom-45'>
                                    {!this.shipDate ? '- -' : DateTimeHelper.formatDateTime(this.shipDate, 'll')}
                                </div>
                            }
                            <div className='text-left'>
                                {this.deliveryDateLabel} Delivery Date:
                            </div>
                            <div className='shipment-details'>
                                {!this.deliveryDate ? '- -' : DateTimeHelper.formatDateTime(this.deliveryDate, 'll')}
                        </div>
                        {this.carrierTrackingUrl &&
                            <div className='shipment-details bottom-45'>                                
                            </div>
                        }
                            {this.carrierTrackingUrl &&
                                <div className='text-left'>
                                    Carrier Tracking Info:
                                </div>
                            }{this.carrierTrackingUrl &&
                                <div className='text-left'>
                                    <a className='left tracking-link' href={this.carrierTrackingUrl} target="_blank" rel="noopener noreferrer">
                                        Click Here
                                    </a>                                    
                                </div>
                             }                                                 
                        </div>                    
                    }                    
                </div>
                <SmallCarrierDisclaimer />
            </div>
        );
    }
}
